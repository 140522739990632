import { useState } from "react"

import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import {
  ORG_PORTFOLIO_FORM_ID,
  OrganizationPortfolioStep,
} from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationPortfolioStep/OrganizationPortfolioStep"
import { useOrganizationPortfolioSizeForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationPortfolioStep/useOrganizationPortfolioStepForm"
import {
  ORG_ROLE_FORM_ID,
  OrganizationRoleStep,
  TRoleRadioButton,
} from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationRoleStep/OrganizationRoleStep"
import { useOrganizationRoleForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationRoleStep/useOrganizationRoleForm"
import {
  ORG_SERVICES_FORM_ID,
  OrganizationServicesStep,
} from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationServicesStep/OrganizationServicesStep"
import { useOrganizationServicesForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationServicesStep/useOrganizationServicesForm"
import { OnboardingCard } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import {
  ORG_NAME_FORM_ID,
  OrganizationNameStep,
} from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationNameStep/OrganizationNameStep"
import { useOrganizationNameForm } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationNameStep/useOrganizationNameForm"
import { useAppData } from "src/context/useAppData"
import { usePostIdentify } from "src/data/analytics/queries/analyticsQueries"
import { usePostTrackIntialPortfoliosSize } from "src/data/analytics/queries/portfolioAnalyticsQueries"
import { usePostOrganization } from "src/data/organizations/queries/organizationQueries"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { backgroundGrayV3 } from "src/ui/colors"
import backdropImage from "src/ui/images/photo-orange-pink-tower.jpg"
import backdropImagePortrait from "src/ui/images/photo-orange-pink-tower-portrait.jpg"
import { InlineWizard } from "src/ui/Wizard/InlineWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

/**
 * Create organization flow with more steps for initial tracking for users who do not own any organiazation
 */
export function OnboardingCreateOrganizationWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()

  const { setActiveOrgId } = useAppData()

  const postOrganization = usePostOrganization()
  const [currentStep, setCurrentStep] = useState(0)

  const nameForm = useOrganizationNameForm({
    initialValues: {
      name: "",
    },
    onSubmit: () => {
      setCurrentStep((currStep) => currStep + 1)
    },
  })

  const servicesForm = useOrganizationServicesForm({
    initialValues: {
      profilingServices: [],
      customProfilingServices: "",
    },
    onSubmit: () => {
      setCurrentStep((currStep) => currStep + 1)
    },
  })

  const portfolioSizeForm = useOrganizationPortfolioSizeForm({
    initialValues: {
      initialPortfolioSize: null,
    },
    onSubmit: () => {
      setCurrentStep((currStep) => currStep + 1)
    },
  })

  const roleForm = useOrganizationRoleForm({
    initialValues: {
      profilingRole: null,
      customProfilingRole: "",
    },
    onSubmit: () => {
      handleCreateOrganization()
    },
  })

  const postIdentify = usePostIdentify()
  const postTrackIntialPortfoliosSize = usePostTrackIntialPortfoliosSize()

  function handleCreateOrganization() {
    if (
      portfolioSizeForm.values.initialPortfolioSize &&
      servicesForm.values.profilingServices &&
      roleForm.values.profilingRole
    ) {
      postIdentify.mutate({
        profiling_customer_size_segment:
          portfolioSizeForm.values.initialPortfolioSize,
        profiling_services: servicesForm.values.profilingServices,
        custom_profiling_services: servicesForm.values.customProfilingServices,
        profiling_role: roleForm.values.profilingRole,
        custom_profiling_role: roleForm.values.customProfilingRole,
      })

      postTrackIntialPortfoliosSize.mutate({
        profiling_customer_size_segment:
          portfolioSizeForm.values.initialPortfolioSize,
      })
    }

    return postOrganization.mutate(
      { name: nameForm.values.name },
      { onSuccess: handleCreateOrganizationSuccess }
    )
  }

  function handleCreateOrganizationSuccess(orgResponse: IOrganizationResponse) {
    const newOrgId = orgResponse.id
    setActiveOrgId(newOrgId)
    navigate(Routes.Dashboard.location(), {
      orgIdOverride: newOrgId,
      replace: true,
    })
  }

  const steps: IWizardStep[] = [
    {
      component: <OrganizationNameStep formData={nameForm} />,
      nextButtonLabel: t(langKeys.continue),
      onBack: () => {
        goBack({ defaultPath: Routes.Dashboard.location() })
      },
      nextButtonProps: {
        form: ORG_NAME_FORM_ID,
        loading: postOrganization.isLoading,
      },
      backButtonLabel: t(langKeys.close),
    },
    {
      component: <OrganizationServicesStep formData={servicesForm} />,
      nextButtonLabel: t(langKeys.continue),
      onBack: () => {
        setCurrentStep((currStep) => currStep - 1)
      },
      nextButtonProps: {
        form: ORG_SERVICES_FORM_ID,
        loading: postOrganization.isLoading,
      },
    },
    {
      component: <OrganizationPortfolioStep formData={portfolioSizeForm} />,
      nextButtonProps: {
        form: ORG_PORTFOLIO_FORM_ID,
        loading: postOrganization.isLoading,
      },
      onBack: () => {
        setCurrentStep((currStep) => currStep - 1)
      },
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <OrganizationRoleStep
          formData={roleForm}
          isError={postOrganization.isError}
          options={getOrgUserRoleOptions(t)}
        />
      ),
      nextButtonLabel: t(langKeys.organization_create),
      onBack: () => {
        setCurrentStep((currStep) => currStep - 1)
      },
      nextButtonProps: {
        form: ORG_ROLE_FORM_ID,
        loading: postOrganization.isLoading,
      },
    },
  ]

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
      backgroundColor={backgroundGrayV3}
    >
      <OnboardingCard>
        <InlineWizard currentStep={currentStep} steps={steps} />
      </OnboardingCard>
    </ImageBackdrop>
  )
}

function getOrgUserRoleOptions(t: TTranslateFunction): {
  left: TRoleRadioButton[]
  right: TRoleRadioButton[]
} {
  return {
    left: [
      {
        value: "Host/Owner",
        label: t(langKeys.create_organization_role_host_owner),
      },
      {
        value: "CEO/VP (C-suite)",
        label: t(langKeys.create_organization_role_ceo_vp),
      },
      {
        value: "Regional Manager",
        label: t(langKeys.create_organization_role_regional_manager),
      },
      {
        value: "City/Site Manager",
        label: t(langKeys.create_organization_role_city_site_manager),
      },
    ],
    right: [
      {
        value: "Guest Communications/Frontdesk",
        label: t(
          langKeys.create_organization_service_role_guest_communications_frontdesk
        ),
      },
      {
        value: "Other",
        label: t(langKeys.create_organization_service_other),
      },
    ],
  }
}
