import styled from "styled-components"

import { Checkbox, FormControlLabel, TextField } from "@material-ui/core"
import { FormikProps } from "formik"

import { CreateOrganizationStepWrapper } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { breakpoint } from "src/constants/breakpoints"
import { TProfilingServices } from "src/data/analytics/types/analyticsTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TOrganizationServicesForm = {
  profilingServices: TProfilingServices[]
  customProfilingServices: string
}

export const ORG_SERVICES_FORM_ID = "org-services-form"

export function OrganizationServicesStep({
  formData,
}: {
  formData: FormikProps<TOrganizationServicesForm>
}) {
  const { t } = useTranslate()

  function handleCheck(value: TProfilingServices, checked: boolean) {
    if (checked) {
      formData.setFieldValue("profilingServices", [
        ...formData.values.profilingServices,
        value,
      ])
    } else {
      formData.setFieldValue(
        "profilingServices",
        formData.values.profilingServices.filter((v) => v !== value)
      )
    }
  }

  const serviceCheckboxes = getCheckboxes(t)

  function renderCheckboxes(checkboxes: TServiceCheckbox[]) {
    const otherIsChecked = formData.values.profilingServices.includes("Other")

    return checkboxes.map((checkbox) => (
      <div key={checkbox.value}>
        <FormControlLabel
          control={
            <Checkbox
              name="profilingServices"
              checked={formData.values.profilingServices.includes(
                checkbox.value
              )}
              onChange={(e) => handleCheck(checkbox.value, e.target.checked)}
            />
          }
          label={checkbox.label}
        />
        {checkbox.value === "Other" && otherIsChecked && (
          <div>
            <TextField
              name="customProfilingServices"
              value={formData.values.customProfilingServices}
              onChange={formData.handleChange}
              placeholder={`${t(
                langKeys.create_organization_service_custom_placeholder
              )} (${t(langKeys.input_optional)})`}
              fullWidth
            />
          </div>
        )}
      </div>
    ))
  }

  return (
    <CreateOrganizationStepWrapper
      id={ORG_SERVICES_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.create_organization_what_type_of_services)}
      </MText>
      <CheckboxesWrapper>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.left)}</Checkboxes>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.right)}</Checkboxes>
      </CheckboxesWrapper>
      {formData.touched.profilingServices &&
        formData.errors.profilingServices && (
          <MBanner type="error">{formData.errors.profilingServices}</MBanner>
        )}
    </CreateOrganizationStepWrapper>
  )
}

type TServiceCheckbox = {
  value: TProfilingServices
  label: string
}

function getCheckboxes(t: TTranslateFunction): {
  left: TServiceCheckbox[]
  right: TServiceCheckbox[]
} {
  return {
    left: [
      {
        value: "Short-term rentals",
        label: t(langKeys.create_organization_service_short_term_rental),
      },
      {
        value: "Long-term rentals",
        label: t(langKeys.create_organization_service_long_term_rental),
      },
      {
        value: "Hotel",
        label: t(langKeys.create_organization_service_hotel),
      },
    ],
    right: [
      {
        value: "Other",
        label: t(langKeys.create_organization_service_other),
      },
    ],
  }
}

const CheckboxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.M};

  @media (${breakpoint.mediumUp}) {
    grid-template-columns: 1fr 1fr;
  }
`

const Checkboxes = styled.div`
  display: grid;
  gap: ${spacing.S};
`
